<template>
    <div class="data-page-content rowFlex" style="flex-flow: column; justify-content: center; margin-left: 8px;">
        <div class="rowFlex mb-40">
            <div class="data-item-wrap">
                <DataItemPanelLeftBorder/>
                <div class="data-item-panel" id="program-data"></div>
            </div>
            <div style="margin-right: 40px;">
                <div class="data-item-panel cumulative-data" style="height: 160px">
                    <div>
                        <div>累计访问情况</div>
                        <div>{{ visitTotalCount }}人次</div>
                    </div>
                    <!--                    <div>-->
                    <!--                        <div>累计购买情况</div>-->
                    <!--                        <div>{{ buyTotalCount }}人次</div>-->
                    <!--                    </div>-->
                    <div>
                        <div>累计处理工单</div>
                        <div>{{ buyTotalCount }}次</div>
                    </div>
                </div>

                <div class="data-item-panel order-activity" style="height: 110px">
                    <div class="rowFlex">
                        <span>最新公告</span>
                        <div class="swiper-order"
                             style="height: 24px;overflow: hidden;max-width: calc(100% - 65px - 30px)"
                             :key="adminObj.id">
                            <div class="swiper-order-wrapper">
                                <div class="swiper-slide swiper-no-swiping rowFlex"
                                >
                                    <span v-if="adminObj.createTime">{{ adminObj.createTime }}</span>
                                    <!--                                    <span class="desc">{{ order.userName }}购买“{{ order.itemName }}”</span>-->
                                    <span>{{ adminObj.title }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="rowFlex">
                        <span>最新发帖</span>
                        <div style="max-width: calc(100% - 65px - 30px)">
                            <div class="swiper-activity" style="height: 24px;overflow: hidden;" :key="postl.id">
                                <div class="swiper-activity-wrapper">
                                    <div class="swiper-slide swiper-no-swiping rowFlex"
                                    >
                                        <span class="descl">{{ postl.createTime || '-' }}</span>
                                        <span class="descl">{{ postl.nickname || '-' }}</span>
                                        <template>
                                            <span
                                                    class="desc">{{ postl.title }}</span>
                                        </template>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="data-item-wrap right-data">
                <DataItemPanelLeftBorder/>
                <div class="data-item-panel">
                    <div class="data-item-title">最新物业服务工单动态</div>
                    <div style="width: 375px;height: 517px;overflow: hidden">
                        <div class="workOrder-wrapper">
                            <div class="workOrder-item" v-for="(item,index) in ticketArr" :key="index+item.nickName">
                                <div class="work-item work-item-h">
                                    <div class="order-top">
                                        <img src="./images/yonghu.png" v-if="item.topic == '用户提交报事'">
                                        <img src="./images/xitong.png" v-else>
                                        <div> {{ item.topic }}</div>

                                    </div>
                                    <div>{{ dayjs(+item.date).format("MM-DD") }}</div>
                                </div>
                                <div class="work-item">
                                    <div style="margin-right: 20px">{{ item.topic == '用户提交报事' ? '用户昵称' : '处理人' }}</div>
                                    <div>{{ item.nickName }}</div>
                                </div>
                                <div class="work-item" style="align-items:start">
                                    <div style="margin-right: 20px;width: 80px;flex: 0 0 auto;">报事内容</div>
                                    <div>{{ item.ticketContent }}</div>
                                </div>
                                <img src="./images/caitiao.png" class="caiTiao">
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div class="data-item-wrap">
                <!--                <DataItemPanelLeftBorder/>-->
                <div style="width: 430px"></div>
                <!--                <div class="data-item-panel">-->
                <!--                    <div class="data-item-title">闪购商品订单</div>-->
                <!--                    <div id="mall-order-data"></div>-->
                <!--                    <div style="width: 375px"></div>-->
                <!--                </div>-->
            </div>
        </div>

        <div class="rowFlex mb-40">
            <div class="data-item-wrap">
                <DataItemPanelLeftBorder/>
                <div class="data-item-panel">
                    <div class="data-item-title">模块访问人次</div>
                    <div id="module-data"></div>
                </div>
            </div>

            <div class="data-item-wrap" style="margin-left: 0">
                <DataItemPanelLeftBorder/>
                <div class="data-item-panel" style="height: 260px">
                    <div class="data-item-title" style="width: 850px">“棒棒我鸭”小程序联名“虎牢关”系列传统文化IP</div>
                    <div class="hulao">
                        <div class="item-mo">
                            <div class="ju">
                                <div style="position: relative" class="gaungWra">
                                    <img src="./images/dizuo.png" class="di">
                                    <img src="./images/guangzhu.png" class="guang">
                                </div>

                                <img src="https://cosmos-static.oss-cn-shanghai.aliyuncs.com/dashujutupian/zhangfei.png"
                                     style="width: 130px;height: 140px" class="ren">
                            </div>
                        </div>
                        <div class="item-mo">
                            <div class="ju">
                                <div style="position: relative" class="gaungWra">
                                    <img src="./images/dizuo.png" class="di">
                                    <img src="./images/guangzhu.png" class="guang">
                                </div>
                                <img src="https://cosmos-static.oss-cn-shanghai.aliyuncs.com/dashujutupian/guanyu.png"
                                     style="width: 116px;height: 140px" class="ren">
                            </div>
                        </div>
                        <img src="./images/bangbang.png" style="width: 160px;height: 160px;margin-left: 10px"
                             class="ren">
                        <div class="item-mo">
                            <div class="ju">
                                <div style="position: relative" class="gaungWra">
                                    <img src="./images/dizuo.png" class="di">
                                    <img src="./images/guangzhu.png" class="guang">
                                </div>
                                <img src="https://cosmos-static.oss-cn-shanghai.aliyuncs.com/dashujutupian/yuefei.png"
                                     style="width: 120px;height: 140px;" class="ren">
                            </div>
                        </div>
                        <div class="item-mo">
                            <div class="ju">
                                <div style="position: relative" class="gaungWra">
                                    <img src="./images/dizuo.png" class="di">
                                    <img src="./images/guangzhu.png" class="guang">
                                </div>
                                <img src="https://cosmos-static.oss-cn-shanghai.aliyuncs.com/dashujutupian/lvbu.png"
                                     style="width:  120px; height:140px" class="ren">
                            </div>
                        </div>
                    </div>
                    <!--                    <div id="community-report-people-data"></div>-->
                </div>
            </div>

            <!--            <div class="data-item-wrap">-->
            <!--                <div class="data-item-panel">-->
            <!--                    <div class="data-item-title">“棒棒我鸭”小程序联名“虎牢关”系列传统文化IP</div>-->
            <!--                    <DataItemPanelLeftBorder/>-->
            <!--                    <div class="data-item-panel" style="width: 890px">-->

            <!--                    </div>-->
            <!--                </div>-->
            <!--            </div>-->

            <div class="data-item-wrap">
                <!--                <DataItemPanelLeftBorder/>-->
                <div style="width: 430px"></div>
                <!--                <div class="data-item-panel" style="background-image: none">-->
                <!--                    <div class="data-item-title">各类帖子占比</div>-->
                <!--                    <div id="post-data"></div>-->
                <!--                    <div style="width: 385px"></div>-->
                <!--                </div>-->
            </div>

        </div>


        <div class="rowFlex">
            <div class="data-item-wrap" style="margin-right: 30px">
                <DataItemPanelLeftBorder/>
                <div class="data-item-panel">
                    <div class="data-item-title">最新用户提交问卷</div>
                    <div id="buy-service-data" style="overflow: hidden">
                        <div class="service-wrapper">
                            <div class="service-item" v-for="(item,index) in  questionnaireArr" :key="index">
                                <div class="mobilePhone">
                                    {{ item.phone.replace(/\*/g, '∗') }}
                                </div>
                                <div class="mobileText">
                                    {{ item.surveyName }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div style="display: flex;margin-right: 35px;justify-content: space-between;width: 915px;padding-right: 5px">
                <div class="data-item-wrap" style="margin-right: 30px">
                    <DataItemPanelLeftBorder/>
                    <DataItemPanelLeftBorder/>
                    <div class="data-item-panel">
                        <div class="data-item-title">社区发帖/回复数</div>
                        <div id="community-report-post-data"></div>
                    </div>

                </div>

                <div class="data-item-wrap">
                    <DataItemPanelLeftBorder/>
                    <div class="data-item-panel">
                        <div class="data-item-title">摘苹果/兑现金人数</div>
                        <div id="apple-exchange-data"></div>
                    </div>
                </div>
            </div>


            <div style="margin-bottom: 0; ">
                <div class="data-item-wrap ">
                    <DataItemPanelLeftBorder  />
                    <!--          <div class="data-item-panel" id="apple-create-consumption-data" style="width: 419px"></div>-->
                    <div class=" lease-situation data-item-panel"
                         style="width: 419px;height: 260px">
                        <div class="data-item-title" style="margin-bottom: 0">工单统计</div>
                        <div class="lease-situation-chart" style="margin-top: 20px;display: flex;align-items: center">
                            <div class="info"  style="margin-top: 12px">
                                <div class="room">
                                    <p class="num">{{ticketCount}}个</p>
                                    <p class="text">工单总数</p>
                                </div>
                                <div class="area">
                                    <p class="num">{{evaluate || 0}}</p>
                                    <p class="text" style="min-width: 90px">平均评价星级</p>
                                </div>
                            </div>
                            <div id="ticket-data"></div>
                        </div>
                    </div>
                </div>
                <!--                <div class="data-item-wrap">-->
                <!--                    <DataItemPanelLeftBorder/>-->
                <!--                    <div class="data-item-panel" id="apple-exchange-drop-data"></div>-->
                <!--                </div>-->
            </div>

        </div>
    </div>
</template>

<script>
    import * as echarts from 'echarts';
    import Swiper from 'swiper';
    import DataItemPanelLeftBorder from "@/views/BigData/DataItemPanelLeftBorder";
    import {
        adminQueryPostlUrl,
        getAdminQueryUrl,
        queryActivityByRegionCodeUrl,
        queryAppleCountUrl,
        queryBigDataTicketUrl,
        queryBrowseServiceCountNumberUrl,
        queryEvaluateScoreUrl,
        queryExchangeAppleCountUrl,
        queryMallOrderCountUrl,
        queryNewOrderUrl,
        queryPointsManageUrl,
        queryPostCountByRegionCodeUrl,
        queryPostPercentageByRegionCodeUrl,
        queryPostPersonCountByRegionCodeUrl,
        querySocialCountAndAppleTreeCountUrl,
        querySurveyBigDataUrl, queryTenantRoomAreaUrl, queryTickerTypeProportionUrl,
        queryTicketLogUrl, queryTicketStatusCountUrl,
        regionAvtiveUserDataUrl
    } from "@/requestUrl";
    import dayjs from 'dayjs';
    import {createEvaluateBarSeries, initWs, updateSingleBarChartCount} from "@/views/BigData/tools";

    // 通用的柱状图label样式配置
    const commonBarLabel = {
        show: true,
        position: 'inside',
        fontWeight: 'bold',
        color: '#fff',
        formatter: (e) => {
            if (e.data !== 0) {
                return e.data;
            }
            return '';
        }
    };
    let charts = [];
    //工单类型
    const workOrderList = {
        'clean':'清洁',
        "warranty": "保修",     //保修
        "goods": "物品进出",           //物品进出
        "other": "其他",       //其他
        "complain": "投诉",    //投诉
        "windowOpen": "气窗开启",   //气窗开启
        "cargoLadder": "公共区域秩序",  //公共区域秩序
        "homeRepair": "上门维修",  //上门维修
        "anAbnormality": "异常"    //异常
    }

    export default {
        name: "BigDataPageTwo",
        components: {
            DataItemPanelLeftBorder,
        },
        mounted() {
            this.initPage();
            this.beamFun();
        },
        data() {
            return {
                evaluate:0,
                ticketCount: 0,
                beamTimer: null,//闪光光柱
                adminObj: {},//最新公告
                postl: {},//最新帖子
                dayjs,
                ticketArr: [],//工单数组
                tickeIndex: 0,//工单长度
                indexLength: 0,
                questionnaireArr: [],//最新用户提交问卷
                timerT: 3000,
                workTimer: null,
                questionnaireTimerOut: null,
                regionCode: this.$vc.getCurrentRegion().code,
                newOrderList: [], // 新订单
                newActivityList: [],
                newActivityInfo: {},
                visitTotalCount: 0,  // 累计访问次数
                buyTotalCount: 0, //累计购买人次
                socket: null,
                ratio: 1,
            }
        },
        beforeDestroy() {
            clearInterval(this.beamTimer);
            clearInterval(this.workTimer);
            clearInterval(this.questionnaireTimerOut);
            charts.forEach(chart => chart.dispose());
            charts = [];
            if (this.socket) {
                this.socket.close(3000, '页面关闭');
            }
        },
        methods: {
            // 获取工单
            async queryTenantRoomArea() {

                let re = await this.$fly.get(queryTickerTypeProportionUrl, {
                    regionCode: this.regionCode,
                })
                let reList = re.data;
                console.log('工单类型');
                console.log(re);
                const {clean, warranty, goods, other, complain, windowOpen, cargoLadder, homeRepair, anAbnormality} = re.data;
                return this.$fly.get(queryTicketStatusCountUrl, {
                    regionCode: this.regionCode,
                })
                    .then(res => {
                        if (res.code !== 0) {
                            return;
                        }
                        this.ticketCount = res.data.count;
                        this.evaluate = re.data.evaluate;
                        let arrL = [
                            {name: '公共区域清洁', value: clean || 0,label: {color: 'rgba(255,90,90, 1)'}},
                            {name: '公共区域报修', value: warranty || 0,label: {color: 'rgba(41, 216, 180, 1)'}},
                            {name: '气窗开启', value: windowOpen || 0,label: {color: 'rgba(235, 169, 15, 1)'}},
                            {name: '公共区域秩序', value: cargoLadder || 0,label: {color: 'rgba(155, 119, 239, 1)'}},
                            {name: '物品进出', value: goods || 0,label: {color: 'rgba(42, 194, 59, 1)'}},
                            {name: '上门维修', value: homeRepair || 0,label: {color: 'rgba(30, 196, 255, 1)'}},
                            {name: '投诉', value: complain || 0,label: {color: 'rgba(231, 215, 21, 1)'}},
                            {name: '其它', value: other || 0,label: {color: 'rgba(161, 36, 255, 1)'}},
                        ]
                        arrL = arrL.filter(item => item.value != 0)
                        return this.initEChart('ticket-data', {
                            // color: [
                            //     'rgb(255,90,90)',
                            //     'rgba(41, 216, 180, 1)',
                            //     'rgba(235, 169, 15, 1)',
                            //     'rgba(155, 119, 239, 1)',
                            //     'rgba(42, 194, 59, 1)',
                            //     'rgba(30, 196, 255, 1)',
                            //     'rgba(231, 215, 21, 1)',
                            //     'rgba(161, 36, 255, 1)',
                            //     'rgb(13,0,255)',
                            // ],
                            grid:{
                                top:"60%",
                                left:"0%",
                                right:"0%",
                            },
                            series: [

                                {
                                    name: '工单统计',
                                    type: 'pie',
                                    radius: ['20', '50%'],
                                    itemStyle: {
                                        borderWidth: 6,
                                        borderCap: 'butt',
                                        borderColor: '#021659'
                                    },
                                    label: {
                                        formatter: '{b}\n{c}%',
                                        width: 50,
                                        height: 100,
                                        align: 'left',
                                        overflow: 'break',
                                        fontSize: 12,
                                        show: true,
                                        position: 'outside',

                                    },
                                    labelLine: {
                                        show: true,
                                        /*length: 30,
                                        length2: 20,*/
                                    },
                                    data: arrL ,
                                }
                            ]

                        })
                    })
            },
            //闪光效果
            beamFun() {
                let gaungArr = document.getElementsByClassName('guang');
                this.beamTimer = setInterval(() => {
                    for (let i = 0; i < gaungArr.length; i++) {
                        if (gaungArr[i].style.opacity != 0) {
                            gaungArr[i].style.opacity = 0;
                            gaungArr[i].style.height = 0;
                        } else {
                            gaungArr[i].style.opacity = 1;
                            gaungArr[i].style.height = '80px';
                        }

                    }
                }, 1000)

            },
            //后台查询公告
            getAdminQuery() {
                return this.$fly.post(getAdminQueryUrl, {
                    "regionCode": this.regionCode,//区域code 必填
                    "startDate": dayjs().subtract(1, 'month').format("YYYY-MM-DD") + " 23:59",//查询发布结束时间 必填
                    "endDate": dayjs().format("YYYY-MM-DD") + " 23:59",//查询发布开始时间 必填
                    "title": "",//公告名称
                    "state": 2,//公告状态 1.未上架2.已上架3.已下架
                    "pageNo": 1,
                    "pageSize": 10,//默认10
                    "type": 1,//1.小程序公告2.登录页公告 必填
                }).then(res => {
                    if (res.code != 0) {
                        return
                    }
                    let arr = res.data.datas || [{}];
                    this.adminObj = arr[0];
                })
            },
            //查询帖子
            adminQueryPostl() {
                return this.$fly.post(adminQueryPostlUrl, {
                    "regionCode": this.regionCode,//区域code 必填
                    "startDate": dayjs().subtract(1, 'month').format("YYYY-MM-DD") + " 23:59",//查询发布结束时间 必填
                    "endDate": dayjs().format("YYYY-MM-DD") + " 23:59",//查询发布开始时间 必填
                    "type": 1,//类型1.普通帖子;2.闲物置换;3.问答;4.求组/转租/拼室友;5.公告;6.活动;7.红包活动;8.交友征婚
                    "search": "",//搜索内容
                    "isDelete": 1,//删除未删除 0.删除;1.未删除 必填
                    "pageNo": 1,//页码 不传查询全部
                    "pageSize": 10,//每页显示条数 默认10
                }).then(res => {
                    if (res.code != 0) {
                        return
                    }
                    let arr = res.data.datas || [{}];
                    this.postl = arr[0];
                })
            },
            async initPage() {
                const that = this;
                if (this.socket) {
                    this.socket.close();
                    this.socket = null;
                }

                this.querySumOrder();
                Promise.all([
                    this.queryBrowsePersonalCount(),
                    // this.queryPostPersonCount(),
                    this.queryPostCount(),
                    // this.queryEvaluateScore(),
                    // this.queryPostPercentage(),
                    this.queryPickApplePersonCount(),
                    this.queryTenantRoomArea(),
                    // this.queryAppleCount(),
                    // this.queryExchangeApple(),
                    // this.queryBuyServiceCount(),
                    // this.queryMallOrder(),
                    this.queryProgramsData(),
                    // ---------
                    this.queryNewOrderInfo(),
                    this.queryNewActivity(),
                    //最新用户提交的问卷
                    this.queryQuestionnaire(),
                    //最新工单
                    this.setWorkScoll(),
                    //查询最新帖子
                    this.adminQueryPostl(),
                    //后台查询公告
                    this.getAdminQuery(),
                    //查询工单总数
                    this.queryTenantRoomArea()
                ])
                    .then(allRes => {
                        // 添加新的实时图表请注意顺序
                        // const [
                        //     moduleViewCountChart, postPersonCountChart, postCountByTypeChart, evaluateChart,
                        //     postPercentageChart, pickApplePersonCountChart, appleCountChart, exchangeAppleChart,
                        //     buyCountChart, mallOrderChart, programsChart,
                        // ] = allRes;
                        const [
                            moduleViewCountChart, postCountByTypeChart,
                            pickApplePersonCountChart,ticketTypeChart,
                            programsChart
                        ] = allRes;
                        const defaultOptions = {
                            direction: 'vertical',
                            slidesPerView: 1,
                            spaceBetween: 20,
                            autoplay: {
                                delay: 1000,
                            },
                            loop: true,
                            observer: true,
                            observeParents: true,
                            wrapperClass: 'swiper-order-wrapper',
                        };
                        const orderSwiper = this.initSwiper('.swiper-order', defaultOptions);
                        const activitySwiper = this.initSwiper('.swiper-activity', {
                            ...defaultOptions,
                            wrapperClass: 'swiper-activity-wrapper'
                        });
                        this.socket = initWs({
                            messageHandler: res => {
                                try {
                                    let data = JSON.parse(res.data);
                                    const dataTypeHandlers = {
                                        trafficStatistics: () => {
                                            programsChart.setOption({
                                                series: [
                                                    {data: data.data.data['新增访问人数']},
                                                    {data: data.data.data['次日新增访问留存人数']},
                                                    {data: data.data.data['访问人数']},
                                                    {data: data.data.data['次日访问留存人数']},
                                                ]
                                            })
                                        },
                                        MallCount: () => updateSingleBarChartCount(moduleViewCountChart, 0, data.data),
                                        enterpriseCount: () => updateSingleBarChartCount(moduleViewCountChart, 1, data.data),
                                        visitingCount: () => updateSingleBarChartCount(moduleViewCountChart, 2, data.data),
                                        meetRoomCount: () => updateSingleBarChartCount(moduleViewCountChart, 3, data.data),
                                        postCount: () => updateSingleBarChartCount(moduleViewCountChart, 4, data.data),
                                        appleCount: () => updateSingleBarChartCount(moduleViewCountChart, 5, data.data),
                                        total: () => {
                                            this.visitTotalCount = data.data;
                                        },
                                        latestActivity: () => {
                                            this.newActivityInfo = data.data;
                                            this.newActivityList.splice(this.newActivityList.length - 1, 0, data.data);
                                            activitySwiper.slideNext();
                                        },

                                        newOrder: () => {
                                            this.newOrderList.splice(this.newOrderList.length - 1, 0, data.data);
                                            orderSwiper.slideNext();
                                        },
                                        postPersonCount: () => {
                                            const {send, reply} = data.data;
                                        },
                                        postCountByType: () => {
                                            const {send, reply} = data.data;
                                            postCountByTypeChart.setOption({
                                                series: [
                                                    {
                                                        data: send.map(item => item.count)
                                                    },
                                                    {
                                                        data: reply.map(item => item.count)
                                                    },
                                                ]
                                            })
                                        },
                                        TicketByState: () => {
                                            this.ticketCount = data.data.count;
                                        },
                                        ticketType: () => {
                                            const {clean, warranty, goods, other, complain, windowOpen, cargoLadder, homeRepair, anAbnormality} = data.data;
                                            let arrL = [
                                                {name: '公共区域清洁', value: clean || 0,label: {color: 'rgba(255,90,90, 1)'}},
                                                {name: '公共区域报修', value: warranty || 0,label: {color: 'rgba(41, 216, 180, 1)'}},
                                                {name: '气窗开启', value: windowOpen || 0,label: {color: 'rgba(235, 169, 15, 1)'}},
                                                {name: '公共区域秩序', value: cargoLadder || 0,label: {color: 'rgba(155, 119, 239, 1)'}},
                                                {name: '物品进出', value: goods || 0,label: {color: 'rgba(42, 194, 59, 1)'}},
                                                {name: '上门维修', value: homeRepair || 0,label: {color: 'rgba(30, 196, 255, 1)'}},
                                                {name: '投诉', value: complain || 0,label: {color: 'rgba(231, 215, 21, 1)'}},
                                                {name: '其它', value: other || 0,label: {color: 'rgba(161, 36, 255, 1)'}},
                                            ]
                                            this.evaluate = data.data.evaluate;
                                            arrL = arrL.filter(item => item.value != 0)
                                            ticketTypeChart.setOption({
                                                series: [{
                                                    data: arrL,
                                                }]
                                            })
                                        },
                                        //最新公告
                                        noticeBigData: () => {
                                            this.adminObj = data.data;
                                        },
                                        //最新帖子
                                        postBigData: () => {
                                            this.postl = data.data;
                                        },
                                        postPercentage: () => {
                                        },
                                        points: () => {
                                            const {send, reply} = data.data;
                                            pickApplePersonCountChart.setOption({
                                                series: [
                                                    {data: reply.map(item => item.count)},
                                                    {data: send.map(item => item.count)},

                                                ]
                                            })
                                        },
                                        pointsManage: () => {
                                        },
                                        queryAppleCount: () => {
                                            const {send, reply, changeData} = data.data;
                                            appleCountChart.setOption({
                                                series: [
                                                    {data: send.map(item => -item.count)},
                                                    {data: reply.map(item => item.count)},
                                                    {data: changeData.map(item => item.count)}
                                                ]
                                            })
                                        },
                                        publicScore: () => {
                                        },
                                        ticketCount: () => {
                                            console.log('大数据工单统计')
                                            this.buyTotalCount = data.data;
                                        },
                                        todayOrderCount: () => {
                                        },
                                        //最新用户提交问卷
                                        surveyBigData: () => {
                                            this.questionnaireArr.unshift(data.data);
                                            if (this.questionnaireArr < 4) {
                                                return
                                            } else {
                                                this.questionnaireArr.splice(this.questionnaireArr.length - 4, 1, data.data);
                                                if (!this.questionnaireTimerOut) {
                                                    this.queryQuestionnaire();
                                                }
                                                this.indexLength = this.questionnaireArr.length - 4;
                                            }

                                        },
                                        //工单
                                        ticketBigData: () => {
                                            this.ticketArr.unshift(data.data);
                                            if (this.ticketArr.length < 4) {
                                                return
                                            } else {
                                                this.ticketArr.splice(this.ticketArr.length - 5, 5);
                                                this.ticketArr.push(...this.ticketArr.slice(0, 4));
                                                // this.ticketArr.splice(this.ticketArr.length, 1, data.data);

                                                if (!this.workTimer) {
                                                    this.setWorkScoll();
                                                }

                                                this.indexLength = this.ticketArr.length - 4;
                                            }
                                        }
                                    }
                                    try {
                                        dataTypeHandlers[data.method]();
                                    } catch (err) {
                                    }
                                } catch (err) {
                                }
                            }
                        })
                    })
            },
            //设置工单滚动
            setWorkScoll() {

                return this.$fly.get(queryTicketLogUrl, {
                    regionCode: this.regionCode
                }).then(res => {
                    if (res.code != 0) {
                        return
                    }

                    clearInterval(this.workTimer);
                    let workOrderWrapper = document.getElementsByClassName('workOrder-wrapper')[0];

                    this.ticketArr = res.data;
                    this.tickeIndex = this.ticketArr.length;
                    if (this.tickeIndex >= 4) {
                        let arr = this.ticketArr.slice(0, 4);
                        this.ticketArr = [...this.ticketArr, ...arr];

                    }

                    let setTop = 0;
                    this.workTimer = setInterval(() => {
                        setTop -= 137;
                        if (setTop <= -(this.tickeIndex + 1) * 137) {
                            workOrderWrapper.style.transition = 'all 0s';
                            setTop = 0;
                            setTimeout(() => {
                                workOrderWrapper.style.transition = 'all 0.8s';
                                setTop = -137;
                                workOrderWrapper.style.transform = `translateY(${setTop}px)`
                            }, 20)
                        }
                        workOrderWrapper.style.transform = `translateY(${setTop}px)`
                    }, this.timerT)
                    if (this.tickeIndex < 4) {
                        clearInterval(this.workTimer);
                    }
                })


            },
            // 查询新增，留存这类数据
            queryProgramsData() {
                return this.$fly.post(regionAvtiveUserDataUrl, {
                    regionCode: this.regionCode,
                    startDate: dayjs().subtract(7, "day").format("YYYYMMDD"),
                    endDate: dayjs().format("YYYYMMDD"),
                })
                    .then(res => {
                        if (res.code !== 0) {
                            return;
                        }
                        let isNoHaveTodayData = false;
                        Object.entries(([key, val]) => {
                            isNoHaveTodayData = val[val.length - 1] == 0;
                            if (isNoHaveTodayData === false) {
                                return;
                            }
                        })
                        // 小程序数据
                        return this.initEChart('program-data', {
                            color: ['#20B4F3', '#2CC7A7', '#FF5A5A', '#E9B114'],
                            legend: {
                                left: 20,
                                type: 'plain',
                                itemWidth: 24,
                                itemHeight: 8,
                                itemGap: 20,
                                textStyle: {
                                    fontSize: 12,
                                    color: '#fff'
                                },
                                data: ['新访问人数', '次日新访问留存人数', '访问人数', '次日访问留存人数'],
                            },
                            tooltip: {
                                trigger: 'axis',
                                backgroundColor: '#fff',
                                textStyle: {
                                    color: '#000'
                                }
                            },
                            grid: {
                                bottom: 10,
                                left: 0,
                                containLabel: true,
                                borderColor: '#fff',
                                textStyle: {
                                    color: '#fff',
                                }
                            },
                            // height: '80%',
                            xAxis: {
                                type: 'category',
                                boundaryGap: true,
                                data: [],
                                axisLine: {
                                    lineStyle: {
                                        color: '#fff'
                                    }
                                }
                            },
                            yAxis: {
                                type: 'value',
                                axisLine: {
                                    lineStyle: {
                                        color: '#fff',
                                        width: 0,
                                    }
                                },
                                minInterval: 1,
                                // data: [100, 200, 300, 400, 500, 600]
                            },
                            series: [
                                {
                                    name: '新访问人数',
                                    type: 'line',
                                    data: res.data.data['新增访问人数'].slice(0, isNoHaveTodayData ? res.data.title.length - 2 : res.data.title.length - 1),
                                    smooth: true,
                                    lineStyle: {
                                        width: 3,
                                    }
                                },
                                {
                                    name: '次日新访问留存人数',
                                    type: 'line',
                                    data: res.data.data['次日新增访问留存人数'].slice(0, isNoHaveTodayData ? res.data.title.length - 2 : res.data.title.length - 1),
                                    smooth: true,
                                    lineStyle: {
                                        width: 3,
                                    }
                                },
                                {
                                    name: '访问人数',
                                    type: 'line',
                                    data: res.data.data['访问人数'].slice(0, isNoHaveTodayData ? res.data.title.length - 2 : res.data.title.length - 1),
                                    smooth: true,
                                    lineStyle: {
                                        width: 3,
                                    }
                                },
                                {
                                    name: '次日访问留存人数',
                                    type: 'line',
                                    data: res.data.data['次日访问留存人数'].slice(0, isNoHaveTodayData ? res.data.title.length - 2 : res.data.title.length - 1),
                                    smooth: true,
                                    lineStyle: {
                                        width: 3,
                                    }
                                },
                            ]
                        })
                    })
            },
            // 兑换苹果、堕落苹果数
            queryExchangeApple() {
                return this.$fly.get(queryExchangeAppleCountUrl, {
                    regionCode: this.regionCode,
                })
                    .then(res => {
                        if (res.code !== 0) {
                            return;
                        }
                        const data = res.data;
                        // 苹果兑换和掉落
                        return this.initEChart('apple-exchange-drop-data', {
                            color: ['rgba(90, 216, 166, 0.85)', 'rgba(91, 143, 249, 0.85)'],
                            tooltip: {
                                trigger: 'item'
                            },
                            legend: {
                                left: 'center',
                                top: 0,
                                // itemGap: 20,
                                itemWidth: 9,
                                itemHeight: 9,
                                itemStyle: {
                                    borderWidth: 0,
                                    borderCap: 'round'
                                },
                                textStyle: {
                                    color: '#fff',
                                },
                            },
                            series: [
                                {
                                    name: '苹果数量',
                                    type: 'pie',
                                    radius: ['50%', '80%'],
                                    itemStyle: {
                                        borderRadius: 2,
                                        borderColor: 'rgba(7, 65, 182, 0.25)',
                                        borderWidth: 2,
                                        borderCap: 'round',
                                    },
                                    top: '15%',
                                    bottom: 0,
                                    left: 0,
                                    label: {
                                        position: 'inner',
                                        fontSize: 14,
                                        formatter: '{d}%',
                                        color: '#fff'
                                    },
                                    data: [
                                        {name: '坠落苹果数', value: data.corrupts},
                                        {name: '兑换苹果数', value: data.change},
                                    ],
                                }
                            ]
                        })
                    })
            },
            // 产生苹果，消耗苹果 兑换
            queryAppleCount() {
                return null;
                // return this.$fly.get(queryAppleCountUrl, {
                //   regionCode: this.regionCode,
                // })
                //     .then(res => {
                //       if (res.code !== 0) {
                //         return;
                //       }
                //       const send = res.data.send.sort((val, nextVal) => nextVal.time.replaceAll('-', '') - val.time.replaceAll('-', '') >= 0 ? -1 : 0),
                //           reply = res.data.reply.sort((val, nextVal) => nextVal.time.replaceAll('-', '') - val.time.replaceAll('-', '') >= 0 ? -1 : 0),
                //           changeData = res.data.changeData.sort((val, nextVal) => nextVal.date.replaceAll('-', '') - val.date.replaceAll('-', '') >= 0 ? -1 : 0)
                //       // 苹果产生和消耗
                //       return this.initEChart('apple-create-consumption-data', {
                //         // tooltip: {
                //         //     trigger: 'axis',
                //         //     axisPointer: {
                //         //         type: 'shadow'
                //         //     }
                //         // },鼠标悬停
                //         legend: {
                //           data: ['产生苹果数', '消耗苹果数', '兑换苹果数'],
                //           textStyle: {
                //             color: "#fff"
                //           },
                //           left: '0',
                //           top: 0
                //         },
                //         grid: {
                //           left: '3%',
                //           right: '4%',
                //           bottom: '3%',
                //           top: '12%',
                //           containLabel: true
                //         },
                //         xAxis: [
                //           {
                //             type: 'value',
                //             axisTick: {
                //               show: false
                //             },
                //             axisLine: {
                //               show: false,
                //             },
                //             splitLine: {
                //               show: false,
                //             },
                //             // scale: true,
                //             // splitNumber: 1,
                //             axisLabel: {
                //               show: false
                //             }
                //           }
                //         ],
                //         yAxis: [
                //           {
                //             type: 'category',
                //             axisTick: {
                //               show: false
                //             },
                //             data: res.data.send.map(item => {
                //               return item.time.slice(5)
                //             }),
                //             axisLabel: {
                //               textStyle: {
                //                 color: "#fff"
                //               },
                //             }
                //           }
                //         ],
                //         series: [
                //           {
                //             name: '产生苹果数',
                //             type: 'bar',
                //             stack: 'Total',
                //             label: {
                //               show: false,
                //               position: 'right',
                //               color: '#fff'
                //             },
                //             emphasis: {
                //               focus: 'series'
                //             },
                //             itemStyle: {
                //               color: new echarts.graphic.LinearGradient(1, 0, 0, 0, [
                //                 {offset: 0, color: '#29F3FF'},
                //                 {offset: 1, color: '#0084FF'}
                //               ])
                //             },
                //             data: send.map(item => -item.count)
                //           },
                //           {
                //             name: '消耗苹果数',
                //             type: 'bar',
                //
                //             label: {
                //               show: false,
                //               position: 'right',
                //               color: '#fff'
                //             },
                //             emphasis: {
                //               focus: 'series'
                //             },
                //             itemStyle: {
                //
                //               color: new echarts.graphic.LinearGradient(1, 0, 0, 0, [
                //                 {offset: 0, color: '#00FFFF'},
                //                 {offset: 1, color: '#1BFAB7'}
                //               ])
                //             },
                //             data: reply.map(item => item.count)
                //           },
                //           {
                //             name: '兑换苹果数',
                //             type: 'bar',
                //             stack: 'Total',
                //             label: {
                //               show: false,
                //               position: 'left',
                //               color: '#fff'
                //             },
                //             emphasis: {
                //               focus: 'series'
                //             },
                //             itemStyle: {
                //
                //               color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                //                 {offset: 0, color: '#CF8CDB'},
                //                 {offset: 1, color: '#358FE8'}
                //               ])
                //             },
                //             data: changeData.map(item => item.change)
                //           }
                //         ]
                //       })
                //     })
            },
            // 获取摘苹果兑换现金人数
            queryPickApplePersonCount() {
                return this.$fly.get(queryPointsManageUrl, {
                    regionCode: this.regionCode,
                })
                    .then(res => {
                        if (res.code !== 0) {
                            return;
                        }
                        // 苹果兑换现金
                        return this.initEChart('apple-exchange-data', {
                            color: ['rgba(91, 143, 249, 1)', 'rgba(90, 216, 166, 0.88)'],
                            legend: {
                                left: 20,
                                type: 'plain',
                                itemWidth: 24,
                                itemHeight: 8,
                                itemGap: 20,
                                textStyle: {
                                    fontSize: 12,
                                    color: '#fff'
                                },
                                data: ['摘苹果人数', '兑现金人数'],
                            },
                            tooltip: {
                                trigger: 'axis',
                                axisPointer: {
                                    type: 'shadow'
                                }
                            },
                            grid: {
                                borderColor: '#fff',
                                containLabel: true,
                                bottom: 10,
                                left: 0,
                                top: 30,
                                textStyle: {
                                    color: '#fff',
                                }
                            },
                            xAxis: {
                                type: 'category',
                                boundaryGap: true,
                                data: [],
                                axisLine: {
                                    lineStyle: {
                                        color: '#fff'
                                    }
                                }
                            },
                            yAxis: {
                                type: 'value',
                                minInterval: 1,
                                axisLine: {
                                    lineStyle: {
                                        color: '#fff',
                                        width: 0,
                                    }
                                },
                            },
                            series: [
                                {
                                    name: '摘苹果人数',
                                    type: 'line',
                                    data: res.data.reply.map(item => item.count),
                                    smooth: true,
                                    showAllSymbol: true,
                                    symbol: 'emptyCircle',
                                    symbolSize: 5,
                                    itemStyle: {
                                        color: '#00FFEE'
                                    }
                                },
                                {
                                    name: '兑现金人数',
                                    type: 'bar',
                                    data: res.data.send.map(item => item.count),
                                    barWidth: 4,
                                    itemStyle: {
                                        borderRadius: 5,
                                        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                            {offset: 0, color: '#CF8CDB'},
                                            {offset: 1, color: '#358FE8'}
                                        ])
                                    },
                                },
                                {
                                    name: 'lin',
                                    type: ' bar',
                                    barGap: '-100%',
                                    barWidth: 10,
                                    itemStyle: {
                                        color: new echarts.graphic.LinearGradient(0, 0, 0, 0, [
                                            {offset: 0, color: 'rgba(20,200,212,0)'},
                                            {offset: 0.2, color: 'rgba(20,200,212,0.2)'},
                                            {offset: 1, color: 'rgba(20,200,212,0)'}
                                        ])
                                    },
                                    z: -12,
                                    data: res.data.reply.map(item => item.count),
                                },
                            ]
                        })
                    })
            },
            // 查询商城订单
            queryMallOrder() {
                return this.$fly.get(queryMallOrderCountUrl, {
                    regionCode: this.regionCode,
                })
                    .then(res => {
                        if (res.code !== 0) {
                            return;
                        }
                        // 商城订单数据
                        return this.initEChart('mall-order-data', {
                            color: [{
                                type: 'linear',
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [{
                                    offset: 0, color: 'rgba(16, 248, 255, .9)' // 0% 处的颜色
                                }, {
                                    offset: 1, color: 'rgba(91, 155, 249, .9)' // 100% 处的颜色
                                }],
                                global: false // 缺省为 false
                            }],
                            grid: {
                                left: 0,
                                bottom: 0,
                                top: 10,
                                right: 10,
                                containLabel: true,
                                borderColor: '#fff',
                                textStyle: {
                                    color: '#fff',
                                }
                            },
                            xAxis: {
                                type: 'category',
                                data: res.data.map(item => item.time.slice(5)),
                                axisLine: {
                                    lineStyle: {
                                        color: '#fff'
                                    }
                                }
                            },
                            yAxis: {
                                type: 'value',
                                minInterval: 1,
                                axisLine: {
                                    lineStyle: {
                                        color: '#fff',
                                        width: 0
                                    }
                                }
                            },
                            series: [{
                                data: res.data.map(item => item.count),
                                label: commonBarLabel,
                                type: 'bar'
                            }],
                        })
                    })
            },
            // 累计购买情况(人次)
            // 改为了累计报事
            querySumOrder() {
                this.$fly.get(queryBigDataTicketUrl, {
                    regionCode: this.regionCode,
                })
                    .then(res => {
                        if (res.code !== 0) {
                            return;
                        }
                        this.buyTotalCount = res.data;
                    })
            },
            // 各类帖子占比
            queryPostPercentage() {
                return this.$fly.get(queryPostPercentageByRegionCodeUrl, {
                    regionCode: this.regionCode,
                })
                    .then(res => {
                        if (res.code !== 0) {
                            return;
                        }
                        // 各类帖子占比
                        return this.initEChart('post-data', {
                            color: ['rgba(4, 91, 227, 1)', 'rgba(255, 90, 90, 1)', 'rgba(18, 195, 190, 1)', 'rgba(214, 134, 20, 1)'],
                            tooltip: {
                                trigger: 'item'
                            },
                            legend: {
                                right: 0,
                                top: 'middle',
                                orient: 'vertical',
                                itemGap: 20,
                                itemWidth: 12,
                                itemHeight: 12,
                                itemStyle: {
                                    borderWidth: 0,
                                    borderCap: 'round'
                                },
                                textStyle: {
                                    color: '#fff'
                                },
                            },
                            series: [
                                {
                                    name: '帖子占比',
                                    type: 'pie',
                                    radius: ['50%', '80%'],
                                    itemStyle: {
                                        borderColor: '#fff',
                                        borderWidth: 2,
                                        borderCap: 'round'
                                    },
                                    label: {
                                        position: 'inner',
                                        fontSize: 14,
                                        formatter: '{d}%',
                                        color: '#fff'
                                    },
                                    data: [
                                        {name: '普通帖子', value: res.data.count1},
                                        {name: '闲物置换', value: res.data.count2},
                                        {name: '问题求助', value: res.data.count3},
                                        {name: '求租/转租', value: res.data.count4}
                                    ],
                                }
                            ]
                        })
                    })
            },
            // 获取评价数量
            queryEvaluateScore() {
                return this.$fly.get(queryEvaluateScoreUrl, {
                    regionCode: this.regionCode,
                })
                    .then(res => {
                        if (res.code !== 0) {
                            return;
                        }
                        const legendNames = ['未评价', '一星', '二星', '三星', '四星', '五星'];
                        const scoreIndex = {
                            'meetData': 3,
                            'mallData': 0,
                            'visitData': 2,
                            'enterpriseData': 1,
                        };

                        // 评价
                        return this.initEChart('evaluate-data', {
                            color: ['#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#9a60b4'],
                            legend: {
                                data: legendNames,
                                orient: 'vertical',
                                right: 0,
                                top: 'middle',
                                textStyle: {color: '#fff'}
                            },
                            grid: {
                                left: 0,
                                top: '10%',
                                bottom: 0,
                                right: '20%',
                                containLabel: true,
                            },
                            xAxis: [
                                {
                                    type: 'category',
                                    data: ['闪购', '企业服务', '上门服务', '会议室'],
                                    axisLine: {
                                        lineStyle: {color: '#fff'}
                                    }
                                }
                            ],
                            yAxis: [
                                {
                                    type: 'value',
                                    minInterval: 10,
                                    max: 100,
                                    axisLine: {
                                        lineStyle: {color: '#fff'}
                                    },
                                    axisLabel: {
                                        formatter: '{value}%'
                                    }
                                }
                            ],
                            series: createEvaluateBarSeries(res.data, scoreIndex),
                        })
                    })
            },
            // 查询帖子发布数和回复数
            queryPostCount() {
                return this.$fly.get(queryPostCountByRegionCodeUrl, {
                    regionCode: this.regionCode,
                })
                    .then(res => {
                        if (res.code !== 0) {
                            return;
                        }
                        const send = res.data.send.sort((val, nextVal) => nextVal.time.replaceAll('-', '') - val.time.replaceAll('-', '') >= 0 ? -1 : 0),
                            reply = res.data.reply.sort((val, nextVal) => nextVal.time.replaceAll('-', '') - val.time.replaceAll('-', '') >= 0 ? -1 : 0);
                        // 社区帖子发帖回复
                        return this.initEChart('community-report-post-data', {
                            color: ['#6677FC', '#91F3FF'],
                            legend: {
                                left: 20,
                                type: 'plain',
                                itemWidth: 24,
                                itemHeight: 8,
                                itemGap: 20,
                                textStyle: {
                                    fontSize: 12,
                                    color: '#fff'
                                },
                                data: ['社区发帖数', '社区回复数'],
                            },
                            tooltip: {
                                trigger: 'axis',
                                axisPointer: {
                                    type: 'shadow'
                                }
                            },
                            grid: {
                                left: 0,
                                top: 30,
                                bottom: 10,
                                containLabel: true,
                                borderColor: '#fff',
                                textStyle: {
                                    color: '#fff',
                                }
                            },
                            xAxis: {

                                data: [],
                                axisLine: {
                                    lineStyle: {
                                        color: '#ccc',
                                    }
                                }
                            },
                            yAxis: {
                                type: 'value',
                                axisLine: {
                                    lineStyle: {
                                        color: '#ccc'
                                    }
                                },
                                axisLabel: {
                                    formatter: '{value}',
                                    color: '#fff'
                                }
                            },
                            series: [
                                {
                                    name: '社区发帖数',
                                    type: 'line',
                                    data: send.map(item => item.count),
                                    smooth: true,
                                    showAllSymbol: true,
                                    symbol: 'emptyCircle',
                                    symbolSize: 5,
                                    itemStyle: {
                                        color: '#00FFEE'
                                    }
                                },
                                {
                                    name: '社区回复数',
                                    type: 'bar',
                                    data: reply.map(item => item.count),
                                    barWidth: 4,
                                    itemStyle: {
                                        borderRadius: 5,
                                        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                            {offset: 0, color: '#CF8CDB'},
                                            {offset: 1, color: '#358FE8'}
                                        ])
                                    },
                                    areaStyle: {}
                                }
                            ]
                        })
                    })
            },
            // 查询发帖人数，回复人数
            queryPostPersonCount() {
                return this.$fly.get(queryPostPersonCountByRegionCodeUrl, {
                    regionCode: this.regionCode,
                })
                    .then(res => {
                        if (res.code !== 0) {
                            return;
                        }
                        return this.initEChart('community-report-people-data', {
                            color: ['rgba(65, 243, 217, 1)', 'rgba(0, 142, 255, 1)'],
                            legend: {
                                left: 20,
                                type: 'plain',
                                itemWidth: 24,
                                itemHeight: 8,
                                itemGap: 20,
                                textStyle: {
                                    fontSize: 12,
                                    color: '#fff'
                                },
                                data: ['社区发帖人数', '社区回复人数'],
                            },
                            tooltip: {
                                trigger: 'axis',
                                backgroundColor: '#fff',
                                textStyle: {
                                    color: '#000'
                                }
                            },
                            grid: {
                                bottom: 0,
                                left: 0,
                                containLabel: true,
                                borderColor: '#fff',
                                textStyle: {
                                    color: '#fff',
                                }
                            },
                            xAxis: {
                                type: 'category',
                                boundaryGap: true,
                                data: res.data.send.map(item => item.time.slice(5)),
                                axisLine: {
                                    lineStyle: {
                                        color: '#fff'
                                    }
                                }
                            },
                            yAxis: {
                                type: 'value',
                                minInterval: 1,
                                axisLine: {
                                    lineStyle: {
                                        color: '#fff',
                                        width: 0,
                                    }
                                },
                            },
                            series: [
                                {
                                    name: '社区发帖人数',
                                    type: 'line',
                                    data: res.data.send.map(item => item.count),
                                    smooth: true,
                                    lineStyle: {
                                        width: 3,
                                    }
                                },
                                {
                                    name: '社区回复人数',
                                    type: 'line',
                                    data: res.data.reply.map(item => item.count),
                                    smooth: true,
                                    lineStyle: {
                                        width: 3,
                                    }
                                },
                            ]
                        })
                    })
            },
            // 查询购买人次
            // queryBuyServiceCount() {
            //     return this.$fly.get(queryBuyServiceCountUrl, {
            //         regionCode: this.regionCode,
            //     })
            //         .then(res => {
            //             if (res.code !== 0) {
            //                 return;
            //             }
            //             const data = res.data;
            //             return this.initEChart('buy-service-data', {
            //                 color: [{
            //                     type: 'linear',
            //                     x: 0,
            //                     y: 0,
            //                     x2: 0,
            //                     y2: 1,
            //                     colorStops: [{
            //                         offset: 0, color: 'rgba(148, 253, 235, .8)' // 0% 处的颜色
            //                     }, {
            //                         offset: 1, color: 'rgba(91, 119, 249, .8)' // 100% 处的颜色
            //                     }],
            //                     global: false // 缺省为 false
            //                 }],
            //                 grid: {
            //                     borderColor: '#fff',
            //                     left: 0,
            //                     bottom: 0,
            //                     top: 10,
            //                     right: 10,
            //                     containLabel: true,
            //                     textStyle: {
            //                         color: '#fff',
            //                     }
            //                 },
            //                 xAxis: {
            //                     type: 'category',
            //                     data: ['闪购', '企业服务', '上门服务', '会议室'],
            //                     axisLine: {
            //                         lineStyle: {
            //                             color: '#fff'
            //                         }
            //                     }
            //                 },
            //                 yAxis: {
            //                     type: 'value',
            //                     minInterval: 1,
            //                     axisLine: {
            //                         lineStyle: {
            //                             color: '#fff',
            //                             width: 0
            //                         }
            //                     }
            //                 },
            //                 series: [{
            //                     data: [data?.mallCount || 0, data?.enterpriseCount || 0,
            //                         data?.visitCount || 0, data?.meetingCount || 0
            //                     ],
            //                     label: commonBarLabel,
            //                     type: 'bar'
            //                 }],
            //             })
            //         })
            // },
            //最新用户提交问卷
            queryQuestionnaire() {
                return this.$fly.get(querySurveyBigDataUrl, {
                    regionCode: this.regionCode
                }).then(res => {
                    clearInterval(this.questionnaireTimerOut);
                    if (res.code != 0) {
                        return
                    }
                    this.questionnaireArr = res.data;
                    this.indexLength = this.questionnaireArr.length;
                    if (this.indexLength >= 4) {
                        let arr = this.questionnaireArr.slice(0, 4);
                        this.questionnaireArr = [...this.questionnaireArr, ...arr]
                    }
                    let serTop = 0;
                    let serviceWrapper = document.getElementsByClassName('service-wrapper')[0];
                    let buyService = document.getElementById('buy-service-data');
                    let timer = 3000;

                    this.questionnaireTimerOut = setInterval(() => {
                        serTop = serTop - 50;
                        if (serTop <= -(this.indexLength + 1) * 50) {
                            serviceWrapper.style.transition = 'all 0s';
                            serTop = 0;
                            setTimeout(() => {
                                serviceWrapper.style.transition = 'all 0.8s';
                                serTop = -50;
                                serviceWrapper.style.transform = `translateY(${serTop}px)`
                            }, 0)

                        }
                        serviceWrapper.style.transform = `translateY(${serTop}px)`
                    }, timer)
                    if (this.indexLength < 4) {
                        clearInterval(this.questionnaireTimerOut);
                    }
                });

            },
            // 查询各服务的浏览人数
            queryBrowsePersonalCount() {
                return Promise.all([
                    this.$fly.get(querySocialCountAndAppleTreeCountUrl, {
                        regionCode: this.regionCode,
                    }),
                    this.$fly.get(queryBrowseServiceCountNumberUrl, {
                        regionCode: this.regionCode,
                    })
                ])
                    .then(([res1, res2]) => {
                        const data = {...res1.data, ...res2.data};
                        this.visitTotalCount = res1?.data?.total || 0;
                        // 模块访问人次
                        return this.initEChart('module-data', {
                            color: [{
                                type: 'linear',
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [{
                                    offset: 0, color: 'rgba(148, 253, 235, .8)' // 0% 处的颜色
                                }, {
                                    offset: 1, color: 'rgba(91, 119, 249, .8)' // 100% 处的颜色
                                }],
                                global: false // 缺省为 false
                            }],
                            grid: {
                                top: 10,
                                left: 0,
                                right: 0,
                                containLabel: true,
                                borderColor: '#fff',
                                textStyle: {
                                    color: '#fff',
                                }
                            },
                            width: '98%',
                            height: '95%',
                            xAxis: {
                                // type: 'category',
                                data: ['闪购', '企业服务', '上门服务', '会议室', '社区', '我的果树'],
                                axisLine: {
                                    lineStyle: {
                                        color: '#fff'
                                    }
                                },
                                axisLabel: {
                                    interval: 0,
                                },
                            },
                            yAxis: {
                                type: 'value',
                                axisLine: {
                                    lineStyle: {
                                        color: '#fff',
                                        width: 0
                                    }
                                }
                            },
                            series: [{
                                data: [
                                    data?.mallCount || 0, data?.enterpriseCount || 0, data?.visitingCount || 0,
                                    data?.meetRoomCount || 0, data?.postCount || 0, data?.appleCount || 0
                                ],
                                label: commonBarLabel,
                                type: 'bar'
                            }],
                        })
                    })
            },
            // 查询活动
            queryNewActivity() {
                return this.$fly.get(queryActivityByRegionCodeUrl, {
                    regionCode: this.regionCode,
                })
                    .then(res => {
                        if (res.code !== 0) {
                            return false;
                        }
                        this.newActivityInfo = res.data;
                        if (this.newActivityList.length === 0) {
                            this.newActivityList = [res.data, res.data];
                            return false;
                        }
                        return false;
                    })
            },
            // 查询最新订单
            queryNewOrderInfo() {
                return this.$fly.get(queryNewOrderUrl, {
                    regionCode: this.regionCode,
                })
                    .then(res => {
                        if (res.code !== 0) {
                            return false;
                        }
                        if (this.newOrderList.length === 0) {
                            this.newOrderList = [res.data, res.data];
                            return false;
                        }
                        return false;
                    })
            },
            /**
             * 初始化echart
             * @param {String} elName 展示echart图标的元素id
             * @param {Object} options echart配置项
             * @param {Boolean} loopAnimation 是否循环显示动画
             * */
            initEChart(elName, options, loopAnimation = false) {
                const myChart = echarts.init(document.getElementById(elName));
                myChart.setOption(options);
                if (loopAnimation) {
                    setInterval(() => {
                        myChart.clear();
                        myChart.setOption(options);
                    }, 4000)
                }
                charts.push(myChart);
                return myChart;
            },
            initSwiper(elName, options = {}) {
                const tempSwiper = new Swiper(elName, options);
                return tempSwiper;
            }
        }
    }
</script>

<style lang="stylus" scoped>
    @import "./common.styl";


    .lease-situation
        width 400px
        height 240px


        .lease-situation-chart
            display flex
            align-items center

            .info
                .room
                    text-align center

                    .num
                        color #FFD301
                        font-size 14px
                        margin-bottom 0

                    .text
                        color #1987F1
                        font-size 14px

                .area
                    @extends .room


    .data-item-wrap
        @extends .rowFlex
        align-items flex-start

        &:not(:last-of-type)
            margin-right 40px

    .cumulative-data
        width 900px
        height 110px
        overflow hidden
        //height 8.85vw
        margin-bottom 34px
        display flex
        line-height 1
        flex-flow row nowrap
        justify-content center
        align-items center
        box-sizing border-box
        padding 15px 0

        & > div
            &:not(:last-of-type)
                margin-right 140px

            div
                color #FFD301
                font-size 30px

                &:first-of-type
                    color #1987F1
                    font-size 40px
                    margin-bottom 10px

    .order-activity
        width 900px
        height 156px
        overflow hidden
        font-size 24px
        text-align left
        margin-bottom 0
        line-height 1
        text-overflow ellipsis
        display flex
        flex-flow column nowrap
        justify-content center

        & > div
            justify-content flex-start
            position relative
            padding-left 18px
            align-items baseline

            span
                white-space nowrap

                &.desc
                    overflow hidden
                    text-overflow ellipsis
                    flex 0 1 auto

                &.descl
                    overflow hidden
                    text-overflow ellipsis
                    flex 0 0 auto

                &:not(:last-child)
                    margin-right 30px

            div
                &:not(:last-child)
                    margin-right 30px

                p
                    margin-bottom 0

                    &:not(:last-of-type)
                        margin-bottom 15px

            &::after
                position absolute
                content " "
                left 0
                top 0
                //transform translateY(-50%)
                width 8px
                height 24px
                background #FF5A5A

            &:not(:nth-of-type(odd))
                &::after
                    background #F7BA0F

            &:not(:last-of-type)
                margin-bottom 30px

    #program-data
        width 420px
        height 300px

    #mall-order-data
        width 380px
        height 216px

    #module-data, #community-report-people-data, #buy-service-data,
    #community-report-post-data, #post-data, #evaluate-data,
    #apple-exchange-data
        width 380px
        height 176px

    #apple-create-consumption-data, #apple-exchange-drop-data
        width 200px
        height 260px
        padding 20px 0 0 0

    .service-wrapper
        transform translateY(0px)
        transition 0.8s ease-in all

        .service-item
            display flex
            align-items center
            font-size 20px
            margin-bottom 20px

            .mobilePhone
                position relative
                margin-right 20px
                padding-left 20px

                &:after
                    content ''
                    position absolute
                    width 10px
                    height 10px
                    background #1AC9FF
                    border-radius 50%
                    left 0
                    top calc(50% - 5px)

            .mobileText
                white-space nowrap
                overflow hidden
                text-overflow ellipsis
                width 220px

    .hulao
        display flex
        align-items center
        justify-content space-around
        margin-top 28px

        .item-mo
            position relative
            width 160px

            .di
                position absolute
                bottom -10px
                left 15px
                width 100px
                height 40px

            .gaungWra
                position absolute
                bottom -145px

            .guang
                position absolute
                left -6px
                bottom -8px
                z-index 10
                width 140px
                transition all 1s


            .ren
                position relative
                z-index 1
                width 140px
                height 150px

        &:nth-type-of(3)
            width 160px
            height 160px

    .right-data
        position absolute
        top 108px
        right 40px
        margin-right 0 !important
        font-size 20px
        overflow hidden

        .workOrder-wrapper
            transition all 0.8s

            .workOrder-item
                .work-item
                    display flex
                    align-items center
                    margin-bottom 6px

                    &.work-item-h
                        justify-content space-between

                    .order-top
                        display flex
                        align-items center

                        img
                            width 20px
                            height 20x
                            margin-right 10px

                .caiTiao
                    width 100%

    .ju
        position absolute
        left 50%
        top -76px
        transform translateX(-50%)
    #ticket-data
        width 380px
        height 186px


</style>
